.cluster-marker {
  color: #fff;
  background: #1978c8;
  opacity: 0.75;
  border-radius: 50%;    
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#filters {
  display: flex;  
  justify-content: flex-end;
}

.dateRangePicker {  
  margin: 10px 0px;
}

#filters input {
  margin: 10px 10px;
}

#realTimeButton {
  background-color: white;
  color: black;
}

#realTimeButton-click {
  background-color: darkorange;
  color: white;
}