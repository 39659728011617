body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #222222 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.modal-content {
  background-color: #515151 !important;
  color: white;
  padding: 10px 20px;
}

#close-modal-btn {
  color: white;
}

#close-modal-btn:hover {
  color: rgba(255,255,255,0.7);
  text-decoration: none;
}

.carousel-control-next, .carousel-control-prev {
  width: auto !important;
}
.carousel-control-prev { left: -10px !important; }
.carousel-control-next { right: -10px !important; }

.venues-link {
  font-size: 14px; 
  color: white;
  padding: 10px 15px;
}
.venues-link:hover {
  cursor: pointer;
  background: #6b6a6a;
}

.venue-tab, .venue-tab:hover {
  color: #FFF;
}

.venue-tab:focus {
  outline: none;
  border-color: none;
  background: rgba(255,255,255,0.2);
}

/* Inactive Tabs */
.saved-tab {
  font-size: 17px;
  color: gray;
}
/* Hover Tabs*/
.saved-tab:hover {
  background-color: #222222 !important;
  color: white !important;
  border: 0 !important;
  font-size: 17px;
}
/* Actively Selected Tabs */
.saved-tab.active, .saved-tab:focus {
  background-color: #222222 !important;
  color: white !important;
  border: 0 !important;
  border-bottom: 1px solid white !important;
  font-size: 17px;
}
/* Tab Bar */
.mySavedTabClass {
  border-bottom: 0px !important;
  padding-bottom: 20px;
  padding-top: 20px;
}

.empty-list-section {
  color: white;
  text-align: center;
  font-size: 12px;
  padding: 40px;
  margin: 10px 0;
  background: #3B3B3B;
}

.schedual-container {
  background-color: #515151;
  font-size: 12px;
  padding: 10px;
  color: rgba(255,255,255,0.6);
  display: flex;
  flex-direction: column;
}

.no-schedual-container {
  background-color: #515151;
  font-size: 12px;
  padding: 10px;
  color: rgba(255,255,255,0.6);
  display: flex;
  height: 150px;
  justify-content: center;
}

.no-times-container {
  margin-bottom: 20px;
  background-color: #515151;
  font-size: 12px;
  padding: 10px;
  color: rgba(255,255,255,0.6);
  display: flex;
  flex-direction: column;
}

.slide rect {
  width: 10px;
}

.h1 {
    color: red;
    background-color: white;
    background: white;
}

.title-wrapper{
  display: block;
  padding-left: 0%;
}
.nav-title {
  color: white;
}


.drop-item:hover {  
  background-color: red !important;
}

.dropdown-item-title{
  color:white
}
.dropdown-menu {

  min-width: 0em !important;
}