.title-wrapper {
  color: #ffffff;
  font-size: 30px;
  margin-top: 16px;
  text-align: center;
  margin-bottom: 16px;
}

.content-wrapper {
  background-color: rgb(59, 59, 59);
  color: rgb(255, 255, 255);
  padding-left: 24px;
  padding-right: 31px;
  font-size: 14px;
}

.title1 {
  padding-left: 24px;
  padding-right: 31px;
  padding-top: 42px;
  padding-bottom: 20px;
  border-bottom: solid 1px #dddddd;
}

.title2 {
  padding-left: 24px;
  padding-right: 31px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px #dddddd;
}

.how-crowded {
  display: block;
  padding-bottom: 20px;
  font-size: 18px;
}

.lvl-btn-lvl1 {
  width: 100px;
  color: #2d2d2d !important;
  background-color: #53adf6 !important;
  border-color: transparent !important;
}

.lvl-btn-lvl2 {
  width: 100px;
  color: #2d2d2d !important;
  background-color: #83d376 !important;
  border-color: transparent !important;
}

.lvl-btn-lvl3 {
  width: 100px;
  color: #2d2d2d !important;
  background-color: #f5d254 !important;
  border-color: transparent !important;
}

.lvl-btn-lvl4 {
  width: 100px;
  color: #2d2d2d !important;
  background-color: #ff7d05 !important;
  border-color: transparent !important;
}

.lvl-btn-lvl5 {
  width: 100px;
  color: #2d2d2d !important;
  background-color: #f22b29 !important;
  border-color: transparent !important;
}
