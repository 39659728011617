
:root{
  --RED_COLOR: #D5293D;
}
span.text-switch{
  color: white;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10%;

}
.text{
 padding-left:5px;
 font-size: 14px;
}

.cont {
  display:flex ;

  

}

.toggle-switch {
    margin-top: 7px;
    position: relative;
    width: 45px;
    top: 0px;
    margin-left: auto;
    margin-right:5%;
 
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 3px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 18px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "  ";
  padding-left: 10px;
  background-color: var(--RED_COLOR);
  color: #fff;
}
.inner:after {
  content: "  ";
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width: 15px;
  margin: 2.5px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 25px;
  border: 0 solid #bbb;
  border-radius: 3px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}

